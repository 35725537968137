<template>
  <div 
    class="layout-claim__error"
    v-if="hasError">
    <ui-banner
      class="layout-claim__banner"
      :intent="$pepper.Intent.DANGER"
      :title="error.title">
      <template #description>
        <div class="layout-claim__banner-descr">
          <p>{{ error.description }}</p>

          <actions-button
            class="layout-claim__banner-btn"
            :appearance="$pepper.Appearance.PRIMARY"
            icon-post="open"
            :href="{ name: 'sayl-user-portal.home' }"
            :size="$pepper.Size.S"
          >{{ $t('user-portal.go_home_action') }}</actions-button>
        </div>
      </template>
    </ui-banner>
  </div>

  <div 
    class="layout-claim__content view-claim-poap__body" 
    v-else>
    <header class="view-claim-poap__header" v-for="location in poapLocations" :key="location.id">
      <div 
        class="ui-asset view-claim-poap__asset" 
        v-if="location.asset">
        <img 
          class="ui-asset__content"
          :src="location.asset" 
        />
      </div>

      <div class="view-claim-poap__presa">
        <h4 class="view-claim-poap__title">{{ location.name }} x{{ location.quantity }}</h4>
      </div>
      
      <!-- Incentive -->
      <div 
        class="view-claim-poap__incentive"
        v-html="location.incentive"
        v-if="location.incentive"
      ></div>
      
      <!-- Description: Needed? -->
      <div 
        class="view-claim-poap__content" 
        v-if="location.description && false">
        <p 
          class="view-claim-poap__description"
          v-html="location.description"
        ></p>
      </div>
    </header>

    <!-- Add here geoloc if needed-->
  </div>
</template>

<script>
import MixinPoapsClaim from '@/modules/claim/helpers/poaps-claim.js'
import MixinText from '@/helpers/text'

import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'PoapClaimIntro',

  components: {
    UiBanner,
  },

  inject: [
    '$poap',
    '$wallet'
  ],

  mixins: [
    MixinPoapsClaim,
    MixinText,
  ],

  props: {
    hasError: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Object,
      default: null,
    },
  },
}
</script>