<template>
  <main 
    :class="classes"
    :style="style">
    <div 
      class="layout-claim__imgbanner" 
      v-if="banner">
      <img
        id="claim-banner"
        :alt="name"
        class="layout-claim__img"
        :src="banner"
      />
    </div>

    <!-- Loading -->
    <div 
      class="layout-claim__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="layout-claim__container" 
      v-if="!loading">
      <!-- Form -->
      <div 
        class="layout-claim__forms" 
        v-if="!hasError">
        <router-view />
      </div>

      <ui-poaps-intro 
        :error="claimError"
        :has-error="hasError"
      />
    </div>
  </main>
</template>

<script>
import MixinClaimError from '@/modules/claim/helpers/errors.js'
import MixinPoapsClaim from '@/modules/claim/helpers/poaps-claim.js'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'

import UiPoapsIntro from '@/modules/claim/components/ui/poaps-intro.vue'

export default {
  name: 'ClaimPoap',

  components: {
    UiPoapsIntro, 
  },

  inject: [
    '$poap',
    '$user'
  ],
  
  mixins: [ 
    MixinClaimError,
    MixinPoapsClaim,
    MixinRouteLeave,
  ],

  data() {
    return {
      loading: false,
      bannerHeight: 0,
    }
  },

  computed: {
    banner() {
      let ret = null

      let banners = this.$basil.get(this.poapCollection, 'embed.banners',[])
      if(banners && banners.length > 0) {
        ret = banners[0]
      }

      return ret
    },

    classes() {
      return {
        'container': true,
        'layout-claim__body': true,
        '-is-loading': this.loading
      }
    },
 
    style() {
      return `--banner-height: ${this.bannerHeight}px;`
    },

    user() {
      return this.$user.user || null
    }
  },

  methods: {
    resize() {
      let el = document.getElementById('claim-banner')
      if(el && this.banner != null) {
        this.bannerHeight = el.getBoundingClientRect().height

        if(this.bannerHeight < 400) {
          this.bannerHeight = 2 * this.bannerHeight
        }

        if(this.bannerHeight == 0 && this.banner != null) {
          setTimeout(() => this.resize(), 100)
        }
      }
    },

    async reset() {
      try {
        this.loading = true
        this.claimError =  {
          status: null,
          title: null,
          description: null,
        }

        await this.view({ token: this.$route.params.token })
      } catch(e) {
        this.handleClaimErrors(e)
      } finally {
        this.loading = false
        setTimeout(() => this.resize(), 100) 
      }
    }
  },

  mounted() {
    this.reset()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>